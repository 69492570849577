import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/layout'
// import Image from '../components/image'
import SEO from '../components/seo'

import { Element } from 'react-scroll';

import './index.css';
import Photo from '../images/profile.jpg';

const IndexPage = () => (
  <Layout>
    <SEO title="Sophia Zamaria" keywords={[`earth science`]} />
    <Element name="home" className="element">
      <div className="ProfilePhoto">
        <img src={Photo} className="MainPhoto" alt="Sophia Zamaria" />
      </div>
      <div className="Intro">
        <p>I am a dedicated, kind, and hardworking Earth Sciences PhD student currently at the University of Toronto under the supervision of <a href="https://www.es.utoronto.ca/people/faculty/cowling-sharon/">Prof. Sharon Cowling</a> and <a href="https://www.utsc.utoronto.ca/physsci/george-arhonditsis">Prof. George Arhonditsis</a>.</p>
        <p>I am very interested in the natural world and the fascinating, unique, and beautiful planet that is our Earth. Specifically, I am interested in the science, interactions and dynamics of Earth systems: especially in the fields of paleoclimate modeling and reconstruction, hydrogeology, and the implementation of evidence-based and sustainable environmental policy. I am also passionate about geographic information systems, remote sensing, and human-environment interactions.</p>
      </div>
    </Element>
    <Element name="about" className="element">
      <h2>About me</h2>
      <p>I completed my undergraduate degree at the University of Toronto, where I specialized in physical geography, earth systems science, and geographic information systems (GIS). In the upper years of my undergraduate studies, I became interested in how spatial analysis and numerical modeling can be used to resolve controversial natural resource extraction and land use designation. In my third year of my undergraduate degree, I developed a numerical groundwater model that determined the potential radius of contaminant influence of a proposed stone quarry in Bancroft, ON. I also helped to develop a GIS-based decision-making tool that uses weighted influence of social and environmental factors to encourage transparent decision making, specifically in the aggregate industry (Risk et al., 2019 - In Press).</p>
      <p>I was introduced to paleoclimate reconstruction in my last year of my undergraduate degree. I became interested in controversial paleoclimatic reconstructions, such as the Mid-Holocene Green Sahara Paradox. My undergraduate thesis focused on how this Paradox – which is characterised by a discrepancy in reconstructive moisture simulations and paleohydrological evidence – may be resolved by the inclusion of groundwater and subsurface hydrological processes in reconstructive earth system models.</p>
      <p>My interest in paleoclimate reconstruction and earth system models (ESMs) lead me to pursue an MSc degree in earth sciences. I recently completed MSc at the University of Toronto under the supervision of Professor Sharon Cowling. My research focused on resolving archaeological and paleoclimatic controversies in Mid-Holocene Australia through the implementation of a global-scale Mid-Holocene groundwater model.</p>
      <p>I am currently a first-year PhD student at the University of Toronto under the supervision of Professor Sharon Cowling and Professor George Arhonditsis. My current research focuses on using numerical modelling to evaluate existing watershed models and policy (specifically, best management practices) in Lake Erie Watersheds.</p>
    </Element>
    <Element name="cv" className="element Cv">
      <h2>Simplified CV</h2>

      <h3>Education</h3>

      <p>
        <h4>
          <b>Doctor of Philosophy</b><br/>
          Department of Earth Sciences, University of Toronto
        </h4>
        <i>2019–present</i><br/>
        Supervisors: Prof. Sharon Cowling and Professor George Arhonditsis
      </p>

      <p>
        <h4>
          <b>Master of Science</b><br/>
          Department of Earth Sciences, University of Toronto
        </h4>
        <i>2018–2019<br /></i>
        <b>Thesis Title:</b> Palaeoclimate, palaeohydrology, and archaeology of Mid-Holocene Australia: Trends in the human settlement of Australia’s arid interior<br />
        Supervisor: Prof. Sharon Cowling
      </p>

      <p>
        <h4>
          <b>Honours Bachelor of Science</b><br/>
          High Distinction, University of Toronto
        </h4>
        <i>2012–2017<br /></i>
        <b>Thesis Title:</b> The role of groundwater and subsurface hydrological processes in reconstructing the Green Sahara.<br />
      </p>

      <h3>Research Experience</h3>
      <p>
        <h4><b>Research Assistant</b>, University of Toronto</h4>
        <i>2017–present<br /></i>
        Supervisor: Prof. Sharon Cowling<br />
      </p>

      <p>
        <h4><b>Research Intern</b>, Centre for Global Change Science, University of Toronto</h4>
        <i>2016<br /></i>
        Supervisor: Prof. Jing Chen<br />
      </p>

      <p>
        <h4><b>Research Assistant</b>, University of Toronto</h4>
        <i>2013–2017<br /></i>
        Supervisor: Prof. Susan Ruddick<br />
      </p>

      <h3>Professional Experience</h3>
      <p>
        <h4><b>Teaching Assistant</b>, University of Toronto</h4>
        <i>2018<br /></i>
      </p>

      <p>
        <h4><b>Education Assistant</b>, Toronto and Region Conservation Authority</h4>
        <i>2018<br /></i>
      </p>

      <p>
        <h4><b>Program Leader</b>, Toronto Zoo</h4>
        <i>2018<br /></i>
      </p>

      <p>
        <h4><b>Educator</b>, Credit Valley Conservation</h4>
        <i>2018<br /></i>
      </p>

      <p>
        <h4><b>GIS Analyst Intern</b>, World Wildlife Fund Canada</h4>
        <i>2016–2017<br /></i>
      </p>

      <h3>Academic Awards and Scholarships</h3>

      <p>
        <h4 className="award"><b>Queen Elizabeth II Graduate Scholarship in Science and Technology</b></h4>
        <i>2019</i><br/>
        Government of Ontario; University of Toronto
      </p>

      <p>
        <h4 className="award"><b>University of Toronto Fellowship</b></h4>
        <i>2018, 2019</i><br/>
        University of Toronto
      </p>

      <p>
        <h4 className="award"><b>Alexander Graham Bell Canada Graduate Scholarship-Master’s (CGS M)</b></h4>
        <i>2018</i><br/>
        National Sciences and Engineering Research Council of Canada (NSERC)
      </p>

      <p>
        <h4 className="award"><b>F. Kenneth Hare Undergraduate Scholarship in the Environment</b></h4>
        <i>2017<br /></i>
        University of Toronto<br />
      </p>

      <p>
        <h4 className="award"><b>Sidney and Lucille Silver Scholarship</b></h4>
        <i>2017<br /></i>
        University of Toronto<br />
      </p>

      <p>
        <h4 className="award"><b>Dean’s List Scholar</b></h4>
        <i>2015–2017<br /></i>
        University of Toronto<br />
      </p>

      <p>
        <h4 className="award"><b>Thora and Ralph Mills Scholarship</b></h4>
        <i>2016<br /></i>
        University of Toronto<br />
      </p>

      <p>
        <h4 className="award"><b>Outstanding Performance Award for GGR201/203/205/206 </b></h4>
        <i>2015<br /></i>
        University of Toronto<br />
      </p>

      <p>
        <h4 className="award"><b>Ruth Estella Vanderlip Scholarship</b></h4>
        <i>2015<br /></i>
      </p>

      <p>
        <h4 className="award"><b>Muriel McCuaig Memorial Scholarship in French</b></h4>
        <i>2013<br /></i>
        Victoria College, University of Toronto<br />
      </p>

      <p>
        <h4 className="award"><b>Victoria College Bloor Lands Scholarship</b></h4>
        <i>2012<br /></i>
        Victoria College, University of Toronto<br />
      </p>

      <p>
        <h4 className="award"><b>Loran Award, semifinalist</b></h4>
        <i>2012<br /></i>
      </p>

      <p>
        <h4 className="award"><b>Two-year Scholarship, ($80,000)</b></h4>
        <i>2010–2012<br /></i>
        Lester B. Pearson United World College of the Pacific<br />
      </p>

    </Element>
    <Element name="research" className="element Research">
      <h2>Research interests</h2>

      <h3>1. The role of groundwater in reconstructing paleoclimates and improving Earth System Models</h3>
      <p>Groundwater is an integral component of the global hydrological cycle. Studies have found that groundwater interacts with the atmosphere through soil profiles and can influence the atmospheric boundary layer. However, many ESMs do not consider groundwater as a fundamental parameter of the global climate system. I am interested in resolving controversial Mid-Holocene paleoclimate reconstructions through the implementation of a global-scale Mid-Holocene groundwater model (developed by Ying Fan et al., Rutgers University). Agreeance between the groundwater model and paleoenvironmental evidence may suggest improved ESM accuracy through including groundwater as a fundamental climatic parameter. This has many implications for improving climate model projections in a time of rapid anthropogenic climate change in addition to the resolution of controversial paleoclimate reconstructions.</p>

      <h3>2. Implementation of GIS-based spatial analysis and groundwater modeling in sustainable aggregate practices</h3>
      <p>Sustainable aggregate extraction practices must be prioritized as demand for aggregate grows and population becomes greater and denser. GIS-based spatial analysis can be a useful decision-making tool through weighted analysis of risk parameters such as groundwater table depth, hydraulic conductivity, population density and elevation. Numerical groundwater models should also be considered before aggregate extraction, especially in more environmentally-sensitive and populated areas. I am interested in implementing GIS and modeling tools alongside required site examinations in aggregate policy to ensure that aggregate demand is being met responsibly and sustainably.</p>
    </Element>
    <Element name="publications" className="element">
      <h2>Publications</h2>
      <h4>Conference Papers</h4>
      <ul>
        <li>Ma, Qingmiao & Chen, Jing & Li, Yingjie & Croft, Holly & Luo, Xiangzhong & Zheng, Ting & Zamaria, Sophia. (2017). Leaf chlorophyll content estimation from sentinel-2 MSI data. 2915-2918. 10.1109/IGARSS.2017.8127608.</li>
      </ul>

      <h4>Papers In Press</h4>
      <ul>
        <li>C Risk; SA Zamaria; J Chen; J Ke; G. Morgan; J Taylor; K Larsen; SA Cowling. (2019). Using GIS to make transparent and weighted decisions on pit development: Incorporation of industry, social and environmental factors. Canadian Journal of Earth Sciences. (Accepted November 17, 2019; In press)</li>
      </ul>
    </Element>
    <Element name="contact" className="element">
      <h2>Contact</h2>
      <p>
        <a href="https://www.es.utoronto.ca/" className="DepartmentLink">Department of Earth Sciences</a><br />
        University of Toronto<br />
        22 Russell Street<br />
        Toronto, Ontario<br />
        M5S 3B1
      </p>
      <p>
        <h3>Links</h3>
        <a href="mailto:sophia.zamaria@mail.utoronto.ca">Email</a> sophia.zamaria@mail.utoronto.ca (she|her)<br/>
        <a href="https://twitter.com/SophiaZamaria">Twitter</a> @SophiaZamaria<br/>
        <a href="https://www.linkedin.com/in/sophia-zamaria">LinkedIn</a><br/>
        <a href="https://www.researchgate.net/profile/Sophia_Zamaria">ResearchGate</a><br/>
        <a href="https://www.utsc.utoronto.ca/~georgea/">Lab Website</a><br/>
      </p>
    </Element>
  </Layout>
)

export default IndexPage
